.tycn-common-dialog.tycn-common-dialog-ticket-printer-ticket-roll-servicing {
    .md-toolbar-tools {
        h1 {
            font-size: 28px;
            font-weight: 400;
        }
    }

    md-dialog-content {
        md-list {
            md-list-item {
                small {
                    font-size: 12px;
                    opacity: .7;
                }
            }
        }
    }

    md-dialog-actions {
        button {
            &.md-icon-button {
                margin: 0;
                opacity: .5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }
}
