cart-by-schedule-no-places-view {
    display: block;

    .price-item {
        .price-item-name {
        }
        .price-item-value {
        }
        .price-item-in-cart {
            .md-display-1 {
                width: 60px;
            }
            button, md-button {
                margin: 0;
                padding: 1px;
                height: 30px;
                line-height: 18px;
                width: 30px;

                &:disabled {
                    opacity: .5;
                }
            }
        }
    }
}
