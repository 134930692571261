.permissions-list {
    .md-subheader-content {
        md-checkbox {
            margin: 0;
            width: 25px;
            max-width: 25px;
            opacity: .8;
        }
    }
}
