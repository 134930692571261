#client-appeal {
    .client-appeal-new-button {
        cursor: pointer;
    }

    .client-appeal-new-form {
        .secondary-text {
            font-size: 12px;
            user-select: none;
        }
    }

    md-list {

        md-list-item {
            padding: 8px 16px;

            h3 {
                overflow: visible;
                white-space: pre-line;
                text-overflow: inherit;
                font-size: 14px;
                margin-top: 16px;
            }

            h3 + p {
                margin-top: 16px;
                white-space: pre-line;
            }
        }

        md-list-item + md-list-item {
            margin-top: 16px;
        }
    }
}
