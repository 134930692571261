order-payments {
    display: block;

    table.simple {
        thead {
            tr {
                th {
                    &:last-child {
                        padding-right: 0px !important;
                        width: 1%;
                        min-width: 1px;
                    }
                }
            }
        }
        tbody {
            tr {
                &.order-payment-refund {
                    td {
                        background-color: #FCE4EC;
                    }
                }

                td {
                    &.payment-documents {
                        max-width: 20%;
                        width: 20%;
                        font-size: .9em;

                        div {
                            white-space: nowrap;
                        }
                        div + div {
                            margin-top: 8px;
                        }
                    }

                    &:last-child {
                        padding-right: 0px !important;
                        width: 1%;
                        min-width: 1px;
                    }
                }
            }
        }
    }

}
