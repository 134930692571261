#add-edit-price-block-dialog{
    md-dialog-content {
        padding: 0;

        md-tab-content {
            padding: 8px 16px 16px 16px;
        }
        md-switch {
            display: inline-block;
        }
    }
}
