md-dialog#gift-certificate-config-view-edit-dialog {
    md-toolbar {
        md-progress-circular {
            path {
                stroke: white;
            }
        }
    }
    textarea.template {
        background-color: #f0f0f0;
        font-size: 14px;
        font-family: monospace;
        &:focus {
            background-color: #e0e0e0;
        }
    }
}
