order-notifications {
    display: block;

    table.simple {
        tbody {
            tr {
                td {
                    &:first-child {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

}
