gift-config-prices-edit {
    display: block;

    .hint {
        position: absolute;
        left: 2px;
        right: auto;
        bottom: 7px;
        font-size: 12px;
        line-height: 14px;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        color: grey;
        user-select: none;
    }
}
