schedule-legal-contract-edit {
    display: block;

    .legal-name {
        margin-bottom: 18px;

        .label {
            font-size: 1.2rem;
            opacity: .54;

            & + div {
                line-height: 3.2rem;
            }
        }
    }

    md-input-container {
        md-icon {
            cursor: pointer;
            display: inline-block;
        }
    }

}
