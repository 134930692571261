#tycn-order {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        font-size: 14px;

        .goto-orders-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }

        .order-details-summary {
            tbody > tr {
                td + td {
                    padding-left: 12px;
                }
            }
        }

        order-eticket-link {
            color: white;

            a, md-icon {
                color: white;
            }
        }
    }

    .order-detail-form-container {
        padding: 24px;
        margin-bottom: 24px;

        &:not(.customer) table.simple {
            thead > tr > th {
                &:last-child {
                    padding-right: 8px;
                }
            }

            tbody > tr {
                &:hover > td {
                    background-color: #eeeeee;
                }
                & > td {
                    &:last-child {
                        padding-right: 8px;
                    }
                }
            }
        }

        &.customer {

            table {

                thead > tr > th {
                    &:last-child {
                        width: 60px;
                        max-width: 60px;
                        padding-right: 0;
                    }
                }

                tbody > tr > td {
                    &:last-child {
                        width: 60px;
                        max-width: 60px;
                        padding-right: 0;
                    }
                }

            }

            a.email, a.phone {
                color: #333333;
                text-decoration: none;
                border-bottom: 1px dotted #333333;
            }
            a.email:hover, a.email:focus, a.phone:hover, a.phone:focus {
                text-decoration: none;
                border-bottom: 1px solid #333333;
            }

        }

        &.order-positions {
            table {
                tbody > tr > td {

                    &:last-child {
                        .md-button {
                            width: calc(100% - 12px);
                        }
                    }

                    &.order-position-sale-object {
                        md-divider {
                            margin-top: 5px;
                            margin-bottom: 5px;
                            width: 60%;
                        }

                        a {
                            color: black;
                            text-decoration: none;

                            &:hover, &:focus {
                                text-decoration: underline;
                            }
                        }
                    }

                }
            }
        }

        &.status {
            .update-status {
                .rarr:after {
                    content: '→';
                    margin-left: 20px;
                    margin-right: 16px;
                }

                .md-button {
                    font-size: 16px;
                    line-height: 48px;
                    padding-left: 24px;
                    padding-right: 24px;
                }
            }

            .md-button.order-op-resend_order {
                margin-right: 30px;
            }
        }

    }

    ms-widget {
        padding: 0;

        ms-widget-front {
            cursor: pointer;
        }
    }

}
