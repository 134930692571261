gift-config-views {
    display: block;
    table.simple {
        th.vt-n, td.vt-n {
        }
        th.vt-s, td.vt-s {
            min-width: 0px;
            max-width: 10%;
        }
        th.vt-a, td.vt-a {
            min-width: 0px;
            max-width: 1%;
            width: 1%;
        }
        th.vt-a {
            button {
                position: absolute;
                top: 0px;
                right: -12px;
            }
        }
    }
}
