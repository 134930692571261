list-infinite-scroll {
    display: block;

    table {
        thead {
            list-infinite-scroll-headrow {
                display: table-row;

                & > div {
                    display: table-cell;
                    padding: 16px 8px;
                    font-weight: 500;
                    color: rgba(0, 0, 0, 0.54);
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                    text-align: center;
                    white-space: normal;

                    &:first-child {
                        padding-left: 24px;
                    }
                    &:last-child {
                        padding-right: 8px;
                    }
                }
            }
        }
        tbody {
            list-infinite-scroll-row {
                display: table-row;

                & > div {
                    display: table-cell;
                    padding: 16px 8px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                    &:first-child {
                        padding-left: 24px;
                    }
                    &:last-child {
                        padding-right: 8px;
                        width: 5%;
                    }
                }
            }
            &:last-child > list-infinite-scroll-row > div {
                border-bottom: none;
            }
        }
    }
}
