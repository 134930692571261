#planner-schedule-page {
    .planner-schedule-timeline {
        mwl-calendar {
            mwl-calendar-day {
                .cal-day-box {
                    .cal-day-hour {
                        .cal-day-hour-part {
                            height: 12px;
                        }
                        .cal-day-hour-part:last-child {
                            border-bottom-color: #999999;
                        }
                    }
                    .day-event {
                        a.event-item {
                            text-decoration: none;
                            &:hover, &:focus, &:active {
                                border-bottom: 1px dotted rgba(0, 0, 0, 0.56);
                            }
                        }
                        .event-item-hours {
                            color: black;
                            font-weight: bold;
                        }
                        .event-item-action {
                            position: absolute;
                            bottom: -12px;
                            right: -12px;

                            md-icon {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

        }
    }

    .planner-schedule-schedule-edit {
    }

    .planner-schedule-events {
        max-height: 1000px;

        md-input-container {
            padding-bottom: 8px;
            padding-left: 8px;

            .md-errors-spacer {
                display: none;
            }
            md-icon {
                display: inline-block;
                opacity: 0.5;
            }

            &.md-input-focused {
                md-icon {
                    opacity: 1;
                }
            }
        }

        div.planner-schedule-events-item {
            min-height: 50px;
        }
    }

}
