#reportconf-edit {
    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .subtitle {
            margin: 6px 0 0;
        }
    }

    > .content {

        legal-name {
            display: block;
            & + legal-name {
                margin-top: .5em;
            }
        }

        textarea {
            background-color: #f0f0f0;
            font-size: 14px;
            font-family: monospace;
            &:focus {
                background-color: #e0e0e0;
            }
        }

    }
}
