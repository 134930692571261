.utx-dialog-schedule-price-scheme-view {
    .schedule-statistics {
        span {
            display: inline-block;
            margin-bottom: 2px;
        }
    }

    ty-hall, [ty-hall] {

        .ty-hall-directive-hall-scheme-header {
            .ty-hall-directive-hall-scheme-header-selected {
                display: none;
            }
        }

        .ty-hall-directive-hall-scheme .hall-schema-svg {
            .place.noninvolved {
                cursor: default;

                .seat {
                    fill-opacity: inherit;
                    stroke-opacity: inherit;
                }

                .seatnum {
                    fill-opacity: inherit;
                }
            }
        }

    }
}
