#schedule-tickets-page {
    .header {
        .subtitle {
            margin-top: 5px;
            div + div {
                margin-top: 5px;
            }
            .season-none {
                text-decoration: line-through;
            }
        }
    }

    md-sidenav {
        md-list {
            md-list-item {
                &.selected {
                    .md-button {
                        font-weight: 500;
                        color: #000;
                    }
                }
            }
        }
    }
}
