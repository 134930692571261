datetime-edit {
    div[ng-form] {
        white-space: nowrap;

        md-input-container {
            margin-bottom: 0px;
            .md-errors-spacer {
                display: none;
            }
        }
    }
    div[role="alert"] {
        min-height: 24px;
        min-width: 1px;
        // margin-bottom: 18px;

        & > div {
            padding-top: 5px;
            padding-right: 5px;
            padding-left: 0;
            color: rgb(213,0,0);

            font-size: 12px;
            line-height: 14px;
            overflow: hidden;
            transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        }
    }
}
