.schedule-tooltip-panel {
    background: white;
    border-radius: 4px;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);

    min-width: 200px;
    max-width: 400px;
}
.schedule-tooltip-panel > .schedule-tooltip-panel {
    padding: 8px 0;
}
.schedule-tooltip-panel h3 {
    margin-top: 0px;
    margin-bottom: 0px;
}
.schedule-tooltip-panel md-list-item {
    min-height: 30px !important;
}
.schedule-tooltip-panel md-list-item > md-icon {
    margin-top: 4px !important;
    margin-bottom: 2px !important;
}

.schedules-list-datatable {

    & > tbody > tr > td {

        &:last-child {
            padding-right: 8px !important;

            .md-button {
                margin: 0;
            }
        }

        div.text-boxed {
            margin: 0;
        }

        .text-boxed.season-status:not(.season-status-assembled) {
            opacity: 0.5;
            cursor: crosshair;
        }

        schedule-on-sale-informer, schedule-on-sale-informer-simple {
            display: block !important;
            margin-top: 8px;

            .text-boxed {
                border: 1px grey dotted;
                background-color: rgba(0, 0, 0, 0);
                font-size: 10px;
                padding: 4px 6px;
            }
        }

    }
}
