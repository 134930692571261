order-eticket-link {

    a.md-button.md-icon-button {
        margin: 0;
        padding: 0;
        height: auto;
        min-height: inherit;
        width: auto;
        border-radius: 0;
    }

}
