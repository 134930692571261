.tycn-common-dialog.tycn-common-dialog-ticket-blanks-reject {
    .input-form {
        font-size: initial;
        font-weight: normal;

        & > .layout-margin {
            margin: 0;
            & > * {
                &:first-child {
                    margin-left: 0;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .md-errors-spacer {
            display: none;
        }

        label, .md-input, md-select-value {
            color: white;
        }

        label {
            opacity: .8;
        }

         md-input-container:not(.md-input-invalid).md-input-focused .md-input,
        md-select:not([disabled]):focus .md-select-value {
            border-color: white;
        }

        md-radio-button {
            .md-off {
                border-color: white !important;
            }
        
            &.md-checked .md-on {
                background-color:white !important;
            }
        }
    }


    .errors-list {
        width: 100%;
        font-size: initial;
        font-weight: normal;
        margin-bottom: 8px;

        & > div {
            color: white;
            background-color: red;
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    md-dialog-content {
        text-align: center;

        table.simple {
            margin-left: auto;
            margin-right: auto;
            max-width: 600px;

            tbody {
                td {
                    md-input-container {
                        margin-bottom: 0px;
                        margin-top: 0px;
                        font-size: initial;
                    }

                    &.reject-reason-plain {
                        width: 35%;
                        &:hover {
                            cursor: pointer;
                            background-color: #eeeeee;

                            & * {
                                outline: none;
                            }
                        }
                    }
                    &:last-child {
                        padding-right: 8px;
                        width: 1%;

                        button {
                            opacity: 0.6;
                            &:hover {
                                opacity: 1;
                            }

                            md-icon {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
