promocode-rule-edit-priceschemeprices {
    display: block;

    & > div {
        padding-left: 4px;
    }
    .label {
        font-size: 1rem;
        opacity: .54;
    }
}
