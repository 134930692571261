planner-schedule-edit {
    display: block;

    .header {
        md-button, .md-button {
            &.md-icon-button {
                margin: 0;
                padding: 0;
                height: 24px;
                min-height: 24px;
                line-height: 11px;
                width: 24px;

                md-icon {
                    color: white;
                }
                &[disabled="disabled"] {
                    md-icon {
                        color: rgba(255, 255, 255, 0.54);
                    }
                }
            }
        }
    }

    schedule-legal-contract-edit {
        display: block;

        .legal-name {
            margin-bottom: 0px;
        }

        md-input-container {
            margin-top: 0px;

            &.md-input-has-value {
                margin-top: 18px;
            }
        }
    }
}
