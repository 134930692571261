#toolbar {

    #printer-informer {
        width: $toolbarHeight;
        height: $toolbarHeight;
        margin: 0;
        border-radius: 0;

        .printer-informer-count {
            position: absolute;
            left: 16px;
            bottom: 16px;
            width: 12px;
            height: 12px;
            z-index: 10;
            border-radius: 50%;
            text-align: center;
            line-height: 12px;
            font-size: 9px;
        }
    }
    
}
