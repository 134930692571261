$price_colors: #f44336, #e91e63, #9c27b0, #673ab7, #3f51b5, #2196f3, #00bcd4, #009688, #4caf50, #8bc34a, #cddc39, #ffc107, #ff5722, #795548, #607d8b, #49936B, #B8E986, #BB74FA, #50E3C2, #C16F26, #F960A8, #3B6A05, #F4E851, #A0D6F0, #D05C5C, #AA9999;

.ty-hall-tooltip {
    pointer-events: none;
    background-color: #464646;
    border-radius: 4px;
    padding: 0 8px;
    position: absolute;
    z-index: 1001;
    color: white;
    font-size: 13px;

    & > p {
        margin-top: .5em;
        margin-bottom: 0;
        &:last-child {
            margin-bottom: .5em;
        }
    }
}

ty-hall, [ty-hall] {
    min-height: 500px;
    padding: 0 1.5em;

    .ty-hall-directive-hall-scheme-header {
        button.md-button.md-fab.md-primary {
            margin: 0;
            display: block;
        }
        button.md-button.md-fab.md-primary + button.md-button.md-fab.md-primary {
            margin-top: 4px;
        }

        .ty-hall-directive-hall-scheme-header-selected {
            min-height: 36px;
        }
    }

    .ty-hall-directive-hall-scheme {
        .hall-schema-svg {
            .brush .selection {
                fill: yellow;
                fill-opacity: 0.4;
                stroke: lightgrey;
                shape-rendering: crispEdges;
            }

            .section-polygons {
                polygon {
                    fill: none;

                    &.hovered,
                    &:hover {
                        fill: lightgrey;
                        fill-opacity: 0.4;
                        stroke: grey;
                        stroke-opacity: 0.4;
                        stroke-width: 2px;
                        shape-rendering: optimizeSpeed;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                    }
                }

                .label {
                    fill: #666666;
                }
            }

            .place {
                cursor: pointer;

                .seat {
                    stroke: none;
                }

                .seatnum {
                    font-weight: bold;
                }

                &:hover {
                    .seat {
                        fill-opacity: 0.8;
                        stroke: grey;
                    }
                }

                &.selected {
                    .seat {
                        fill-opacity: 0.25;
                        stroke: #f44336;
                    }

                    .seatnum {
                        fill: #000000;
                    }
                }

                &.noninvolved {
                    cursor: default;

                    .seat {
                        fill-opacity: 0.2;
                        stroke-opacity: 0.2;
                    }

                    .seatnum {
                        fill-opacity: 0.8;
                    }
                }
            }
            @for $i from 1 through length($price_colors) {
                .place.color_#{$i} .seat {
                    fill: nth($price_colors, $i);
                    stroke: nth($price_colors, $i);
                }
            }
            //@for $i from 1 through 10 {
            //    .place.color_opacity_#{$i} .seat {
            //        fill-opacity: $i * 0.1;
            //    }
            //}
        }
    }
}
@for $i from 1 through length($price_colors) {
    .place-price-color-#{$i}-fg {
        color: nth($price_colors, $i) !important;
    }
    .place-price-color-#{$i}-bg {
        background-color: nth($price_colors, $i);
    }
}
