#cart-by-schedule-page {
    .header {
        .subtitle {
            margin-top: 5px;
            div + div {
                margin-top: 5px;
            }
            .season-none {
                text-decoration: line-through;
            }
        }
    }

    .order-whiteframe {
        small + small {
            &::before {
                content: ', ';
            }
        }
    }
}
