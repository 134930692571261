#tycn-spot {

    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        font-size: 14px;

        .goto-button {
            margin-right: 16px;
        }

        .subtitle {
            margin: 6px 0 0 0;
        }
    }

}
