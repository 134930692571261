table.simple-datatable-spot-list {

    thead {
        tr > th {
            text-align: center;

            &:first-child {
                width: 90%;
            }
        }
    }

    tbody {
        tr > td {
            padding-right: 0;
        }
    }

}
