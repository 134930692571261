#gift-config-edit {
    md-input-container.skinny {
        margin-bottom: 0px;

        .md-errors-spacer {
            display: none;
        }
    }
    gift-config-prices-edit, gift-config-pricerange-edit, datetime-edit {
        display: block;
    }
}
