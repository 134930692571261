file-list, file-single {
    display: block;

    file-list-item {
        display: block;
        border-bottom: 1px dashed lightgrey;

        &:last-of-type, &:last-child {
            border-bottom: 0px dashed lightgrey;
            margin-bottom: .5em;
        }
    }
}

file-upload {
    label.md-button {
        margin-left: 0px;
    }
}
