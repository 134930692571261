#promocode-edit {
    > .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .subtitle {
            margin: 6px 0 0;
        }
    }

    > .content {
        .promo_control_type {
            margin: -16px 0 0 -16px;
        }

        .promocode-edit-sales-report {
            position: relative;

            .promocode-edit-sales-report-buttons {
                position: absolute;
                top: 0;
                right: 0;
            }
            .promocode-report {
                table {
                    width: 99%;
                    font-size: 12px;
                }
            }
        }
    }
}
