/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/datatables-responsive/css/responsive.dataTables.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/directives/ms-stepper/templates/vertical/vertical.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/crm/tickets_n_printers/ticket-rolls/dialogs/add-edit-ticketroll/ticket_roll_dialog.scss";
@import "main/crm/tickets_n_printers/ticket-rolls/dialogs/ticketroll-blanks/ticketroll-blanks.scss";
@import "main/crm/tickets_n_printers/ticket-rolls/dialogs/add-group-ticketroll/style.scss";
@import "quick-panel/quick-panel.scss";
@import "scss/boards.scss";
@import "scss/hovers.scss";
@import "scss/simple-datatable-page-layout.scss";
@import "scss/simple-datatable.scss";
@import "scss/tycn-common-dialog.scss";
@import "toolbar/toolbar.scss";
@import "auth/login/login.scss";
@import "components/datetime-simple-edit/component.scss";
@import "components/datetime-edit/component.scss";
@import "components/event-select/event-select.component.scss";
@import "components/dump/style.scss";
@import "components/hall-scheme-select/style.scss";
@import "components/file-list/style.scss";
@import "components/legal-select/legal-select.component.scss";
@import "components/json-edit/style.scss";
@import "components/is-active/style.scss";
@import "components/list-infinite-scroll/component.scss";
@import "directives/admin-reserve/admin-reserve.scss";
@import "directives/admin-reserve-multiple/admin-reserve-multiple.scss";
@import "directives/client-appeal/client-appeal.scss";
@import "directives/datatable/datatable.scss";
@import "directives/price-scheme-edit/price-scheme-edit.scss";
@import "directives/hall/hall.directive.scss";
@import "directives/price-scheme-select/style.scss";
@import "directives/schedule-invoice/schedule-invoice.scss";
@import "directives/schedule-quota/schedule-quota.scss";
@import "main/admin/groups/groups.scss";
@import "main/crm/schedule/schedule.scss";
@import "main/reporting/dashboard/dashboard.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.scss";
@import "main/cart/by_schedule/style.scss";
@import "presets/dialog/event-select/style.scss";
@import "presets/dialog/ticket-blanks-reject-widget/style.scss";
@import "presets/dialog/schedule-select/style.scss";
@import "presets/dialog/ticket-printer-ticket-roll-servicing/style.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/printers/printers-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
@import "toolbar/layouts/vertical-navigation/tycn-vertical-navigation-toolbar.scss";
@import "toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.scss";
@import "directives/price-scheme-edit/dialogs/add-edit-price-block/dialog.scss";
@import "main/crm/tickets_n_printers/ticket-templates/ticket_template_edit.scss";
@import "main/gift/config/edit/style.scss";
@import "main/gift/order/edit/style.scss";
@import "main/loyalty/promo/promocode/style.scss";
@import "main/loyalty/promo/promocodes/style.scss";
@import "main/reporting/report_config/edit/style.scss";
@import "main/reporting/report_config/list/style.scss";
@import "services/ticket-printing/dialogs/ticket-printing-status/ticket-printing-status.scss";
@import "main/crm/order/components/order-notifications/order-notifications.component.scss";
@import "main/crm/order/components/order-payments/order-payments.component.scss";
@import "main/crm/order/components/order-eticket-link/order-eticket-link.component.scss";
@import "main/crm/order/views/order-edit/order.scss";
@import "main/crm/events/components/integrations/style.scss";
@import "main/crm/order/components/order-list-order-events/style.scss";
@import "main/crm/order/views/orders/orders.scss";
@import "main/crm/products/dialogs/add-edit-product/add-dialog.scss";
@import "main/crm/schedule/components/schedule-contract-edit/schedule-contract-edit.component.scss";
@import "main/crm/schedule/components/schedule-ext-barcodes/component.scss";
@import "main/crm/schedule/components/schedule-on-sale-informer/style.scss";
@import "main/crm/schedule/components/schedule-on-sale-informer-simple/style.scss";
@import "main/crm/schedule/dialogs/schedule-price-scheme/style.scss";
@import "main/crm/schedule/dialogs/schedule-sale-item-edit/style.scss";
@import "main/crm/planner/schedule/step1/style.scss";
@import "main/crm/schedule/views/schedule-edit/schedule-edit-page.scss";
@import "main/crm/schedule/views/schedule-tickets/styles.scss";
@import "main/crm/planner/schedule/finish/style.scss";
@import "main/crm/planner/schedule/step2/style.scss";
@import "main/crm/seasons/dialogs/season-price-scheme/style.scss";
@import "main/crm/spot/views/hall-edit/style.scss";
@import "main/crm/spot/views/spot-edit/spot-edit.scss";
@import "main/crm/spot/views/spots-list/spots.scss";
@import "main/crm/tickets_n_printers/components/printer-status/printer-status.component.scss";
@import "main/gift/config/component/config-activation-ttl-edit/style.scss";
@import "main/gift/config/component/config-views/style.scss";
@import "main/gift/config/dialog/config-view-edit/style.scss";
@import "main/loyalty/promo/components/promocode-edit-value/component.scss";
@import "main/loyalty/promo/components/promocode-rule-edit-itemcount/component.scss";
@import "main/loyalty/promo/components/promocode-rule-edit-priceschemeprices/component.scss";
@import "main/gift/config/component/config-pricerange-edit/style.scss";
@import "main/loyalty/promo/components/promocode-rules-edit/promocode-rules-edit.component.scss";
@import "main/gift/config/component/config-prices-edit/style.scss";
@import "main/cart/by_schedule/components/cart_by_schedule_places/style.scss";
@import "main/cart/by_schedule/components/cart_by_schedule_no_places/style.scss";
@import "main/crm/refbooks/legal_entities/dialogs/legal-entity-contract-edit/style.scss";
@import "main/crm/planner/schedule/dialog/planner-schedule-edit/style.scss";
@import "main/crm/planner/schedule/component/planner-schedule-edit/style.scss";
// endinjector

md-toast {
    &.md-toast-ty-error {
        left: 50%;
        transform: translate3d(-50%, 0, 0);
    }
    &.md-toast-ty-error .md-toast-content {
        line-height: 2.4rem;
        white-space: pre;
        font-size: 1.1em;
        background-color: #f44336;
    }
    &.md-tycn-admin-toast-theme {
        white-space: pre-line;
    }
}

table input[type=text] {
    border: solid 1px #939393;
}

.text-nounderline {
    text-decoration: none;
    &:hover, &:focus {
        text-decoration: none;
    }
}

[role="tabpanel"] {
    transition: none;
}

@for $i from 8 through 48 {
    .font-size-#{$i} md-icon, md-icon.font-size-#{$i} {
        font-size: #{$i}px;
        width: #{$i}px;
        height: #{$i}px;
        min-width: #{$i}px;
        min-height: #{$i}px;
        line-height: #{$i}px;
    }
}
