json-eeedit {
    display: block;

    md-input-container {
        textarea {
            background-color: #f0f0f0;
            font-size: 14px;
            font-family: monospace;
            &:focus {
                background-color: #e0e0e0;
            }
            &.ng-invalid-pattern {
                border-width: 1px 1px 2px 1px !important;
                border-color: red !important;
            }
        }
    }
}
