promocode-rules-edit {
    display: block;
    position: relative;;

    .promocode-rule-add-button {
        position: absolute;
        top: -56px;
        right: -8px;
    }

    .entity-row {
        &:hover {
            background-color: #f0f0f0;
            md-icon {
                opacity: 1;
            }
        }
        md-icon {
            opacity: .7;
        }
    }
    .entity-row + .entity-row {
        margin-top: 5px;
    }

    table.simple {
        thead {
            tr {
                th {
                    min-width: 40px;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding-top: 8px;
                    padding-bottom: 8px;
                    vertical-align: top;

                    &:first-child {
                        padding-left: 8px;
                    }
                    &:last-child {
                        padding-right: 0;
                        padding-left: 0;
                        vertical-align: bottom;
                    }

                    span.bb {
                        opacity: .75;
                        cursor: pointer;
                        border-bottom-color: rgb(3,155,229);
                        border-bottom-style: dashed;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
