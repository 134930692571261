.reportconfs-list-datatable {
    thead {
        tr {
            th {
                &:last-child {
                    width: 1%;
                }
            }
        }
    }
    tbody {
        tr {
            td {

                legal-name {
                    display: block;

                    & + legal-name {
                        margin-top: .75em;
                    }
                }

            }
        }
    }
}
