#quick-panel {

    .printers-tab {

        .md-subheader {

        }

        .printer {
            padding: 8px 8px 8px 16px;

            &:first-child {
                padding-top: 0;
            }

            &:not(.md-primary-bg):hover {
                background-color: rgba(158,158,158,0.2);
            }

            printer-status {
                vertical-align: middle;
            }

            .md-button {
                padding-top: 0;
                padding-bottom: 0;
                min-height: 32px;
                line-height: 32px;

                &.md-icon-button {
                    margin: 0;
                }
            }

        }

    }
}
