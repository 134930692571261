#schedule-invoice {
    table.simple {
        tbody {
            tr {
                td {
                    /* &:first-child {
                        vertical-align: top;
                    } */

                    .md-accent-fg.md-hue-2 {
                        cursor: pointer;
                        border-bottom: 1px dashed;

                        &:hover {
                            border-bottom-color: white;
                        }
                    }
                }
            }
        }
    }

    #schedule-invoice-editor-button {
        cursor: pointer;
    }

}
