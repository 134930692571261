table.simple-datatable-order-list {

    thead {
        tr > th {
            text-align: center;

            &:first-child {
                padding-left: 16px;
            }
            &:last-child {
                padding-right: 8px;
            }
        }
    }
    tbody {
        tr > td {
            padding-right: 0;

            &:first-child {
                padding-left: 16px;
            }
            &:last-child {
                padding-right: 8px;
            }
        }
    }

    .order-number {
        a {
            &:hover, &:focus, &:active {
                text-decoration: none;
            }
        }
    }

    .order-items {
        min-width: 190px !important;
        max-width: 190px;

        .order-item-number {
            line-height: 24px;
            cursor: default;
            display: inline-block !important;

            margin: 4px 4px 4px 0;
            padding-top: 0;
            padding-bottom: 0;

            &:not(.text-nowrap) {
                white-space: normal;
            }

            &:not(.order-item-status-refund):not(.order-item-status-cancel):not(.order-item-status-outdated-reserve) {
                background-color: #90A4AE;
                color: white;
            }
        }

        order-list-order-events {
            font-size: 11px;
        }
    }

    .order-client {
        & > div {
            margin-top: 4px;
            margin-bottom: 4px;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }

            a {
                color: #777777;
                border-bottom: 1px dotted #777777;

                &:hover, &:focus {
                    text-decoration: none;
                    color: #333333;
                    border-bottom: 1px solid #333333;
                }
            }
        }

    }

}
