#planner-schedule-page-finish {
    .header {
        h1, div.md-display-1 {
            white-space: pre;
        }
    }
    table.simple {
        tbody {
            tr {
                td {
                    padding: 8px;
                }
            }
        }
    }
}
