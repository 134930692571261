#login {
    height: 100%;
    background-color: #B5B5B5;
    background-size: cover;

    &[data-m="1"] {
        background: url('/assets/images/backgrounds/january.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="2"] {
        background: url('/assets/images/backgrounds/february.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="3"] {
        background: url('/assets/images/backgrounds/march.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="4"] {
        background: url('/assets/images/backgrounds/april.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="5"] {
        background: url('/assets/images/backgrounds/may.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="6"] {
        background: url('/assets/images/backgrounds/june.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="7"] {
        background: url('/assets/images/backgrounds/july.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="8"] {
        background: url('/assets/images/backgrounds/august.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="9"] {
        background: url('/assets/images/backgrounds/september.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="10"] {
        background: url('/assets/images/backgrounds/october.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="11"] {
        background: url('/assets/images/backgrounds/november.jpg') no-repeat;
        background-size: cover;
    }
    &[data-m="12"] {
        background: url('/assets/images/backgrounds/december.jpg') no-repeat;
        background-size: cover;
    }
    
    #login-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        #login-form {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            background: #FFFFFF;
            text-align: center;

            .logo {
                width: 128px;
                height: 128px;
                line-height: 128px;
                font-size: 86px;
                font-weight: 500;
                margin: 32px auto;
                color: #FFFFFF;
                border-radius: 2px;
            }

            .title {
                font-size: 17px;
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                md-checkbox {
                    margin: 0;
                }

                .remember-forgot-password {
                    font-size: 13px;
                    margin-top: 8px;

                    .remember-me {
                        margin-bottom: 16px
                    }

                    .forgot-password {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 16px
                    }
                }

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;
                }
            }

            .register {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }

                .link {

                }
            }

            .separator {
                font-size: 15px;
                font-weight: 600;
                margin: 24px auto 16px auto;
                position: relative;
                overflow: hidden;
                width: 100px;
                color: rgba(0, 0, 0, 0.54);

                .text {
                    display: inline-block;
                    vertical-align: baseline;
                    position: relative;
                    padding: 0 8px;
                    z-index: 9999;

                    &:before, &:after {
                        content: '';
                        display: block;
                        width: 30px;
                        position: absolute;
                        top: 10px;
                        border-top: 1px solid rgba(0, 0, 0, 0.12);
                    }

                    &:before {
                        right: 100%;
                    }

                    &:after {
                        left: 100%;
                    }
                }
            }

            .md-button {

                &.google,
                &.facebook {
                    width: 192px;
                    text-transform: none;
                    color: #FFFFFF;
                    font-size: 13px;

                    md-icon {
                        color: #FFFFFF;
                        margin: 0 8px 0 0;
                    }
                }

                &.google {
                    background-color: #D73D32;
                }

                &.facebook {
                    background-color: rgb(63, 92, 154);
                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #login {

        #login-form-wrapper {
            padding: 16px;

            #login-form {
                padding: 24px;
                width: 100%;

                form {

                    .md-button {
                        width: 90%;
                    }
                }

                .md-button {

                    &.google,
                    &.facebook {
                        width: 80%;
                    }
                }
            }
        }
    }

}