#ticket-roll-blanks-dialog {
    .search {
        height: 48px;
        min-height: 48px;
        padding: 8px 8px 8px 16px;
        background: #FFFFFF;
        font-size: 16px;

        .icon {
            margin: 0;
            color: grey;
        }

        input {
            color: rgba(0, 0, 0, 0.54);
            border-width: 0;
            padding-left: 16px;
        }

        md-select {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .date-filter-box {
        background-color: #FFFFFF;
        height: 48px;
        margin-left: 8px;
        padding-left: 16px;

        .filter {
            md-datepicker {
                margin-right: 0;
            }
        }
    }
}
