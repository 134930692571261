#gift-order-edit {
    md-input-container.skinny {
        margin-top: 8px;
        margin-bottom: 8px;

        .md-errors-spacer {
            display: none;
        }
    }
}
