#event-select-dialog {
    .md-dialog-content {
        min-width: 50vw;
    }
}
// .event-select-dialog-md-autocomplete-menu {
//     li {
//         line-height: inherit;
//         height: auto;
//         padding-top: 8px;
//         padding-bottom: 8px;
//     }
// }
