@media screen and (min-width: $layout-breakpoint-sm) {
    .tycn-common-dialog:not(.tycn-alert-dialog) {
        min-height: 75vh;
    }
}

.tycn-common-dialog {
    :not(.tycn-common-dialog-no-padding) md-dialog-content {
        padding: 16px;
    }

    &.tycn-common-dialog-no-padding md-dialog-content {
        padding: 0;
    }

    &.tycn-common-dialog-form, .tycn-common-dialog-form {
        md-dialog-actions {
            border-top: 1px solid rgba(0,0,0,.12);
            background-color: rgba(0,0,0,.03);
        }
    }
}
