#tycn-common-dialog-ticket-roll-edit {
    md-dialog-content {
        .md-input-container-pseudo {
            label {
                opacity: .54;
                font-size: 12px;
            }
        }
    }
}
