cart-by-schedule-places-view {
    display: block;

    .prices-toggle {
        border-bottom-width: 1px;
        border-bottom-style: dotted;
        cursor: pointer;

        &:hover, &:focus, &:active {
            border-bottom-style: solid;
        }
    }

    md-content.prices {
        max-height: 380px;

        table {
            width: 100%;

            tbody {
                tr {
                    td {
                        .price-color-item {
                            display: block;
                            width: 12px;
                            height: 12px;
                        }
                    }
                    &.price-block-head td {
                        padding: 4px;
                        border-radius: 4px;
                        color: white;
                        font-size: larger;
                    }
                    &.price-block-price td {
                        padding-top: 4px;
                        padding-bottom: 4px;
                    }
                    &:last-child td {
                        padding-bottom: 12px;
                    }
                    & td:last-child {
                        width: 1%;
                    }
                }
            }
        }
    }

    [ty-hall] {
        svg {
            .place {
                &:not(.color_0):not(.noninvolved):not(.selected) {
                    rect {
                        fill-opacity: .75;
                    }
                    text.seatnum {
                        font-weight: normal;
                    }
                }
                &.selected {
                    rect {
                        fill: black;
                        fill-opacity: 1;
                        stroke: black;
                    }
                    text.seatnum {
                        fill: white;
                        font-weight: bold;
                    }
                }
                &.order_status_in_order {
                    rect {
                        stroke: black;
                        stroke-opacity: .5;
                        stroke-width: 0.5px;
                    }
                }
                &.highlighted {
                    rect {
                        fill: blue;
                        fill-opacity: 1 !important;
                        stroke: blue;
                        stroke-width: 2px;
                    }
                }
            }
        }
    }

}
