.planner-schedule-edit-dialog {
    planner-schedule-edit {
        display: block;
        .planner-schedule-edit-header {
            padding: 16px !important;
        }
    }

    md-dialog-actions {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        background-color: rgba(0, 0, 0, 0.03);
    }
}
