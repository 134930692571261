.tycn-common-dialog.tycn-common-dialog-ticket-printing {
    min-height: auto !important;

    .md-toolbar-tools {
        height: auto;
        max-height: none;
    }

    md-dialog-content {
        min-height: 20px;
    }

    .tycn-dialog-errors-list {
        md-list-item {
            text-align: center;
            background-color: red;
            color: white;
            margin-bottom: 4px;
            font-size: 16px;
            font-weight: 500;
        }
    }

    table.simple {
        .md-title {
            font-weight: 500;
        }

        thead {
            tr {
                th {
                    font-size: 18px;
                    padding: 8px 16px;
                    min-width: initial;

                    &:first-child {
                        padding-left: 8px;
                    }

                    &:last-child {
                        padding-right: 8px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 8px 16px;
                    min-height: 48px;

                    &:first-child {
                        padding-left: 8px;
                    }

                    &:last-child {
                        padding-right: 8px;

                        & > button, md-menu > button {
                            width: calc(100% - 16px);
                        }
                    }

                    md-progress-circular {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
