promocode-rule-edit-itemcount {
    display: block;

    div[role="alert"] > div {
        font-size: 10px;
    }

    .md-errors-spacer {
        display: none;
    }
}
