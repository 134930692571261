.simple-datatable-page-layout, .page-layout.carded.simple-datatable-page-layout {
    .center {
        .header {
            padding: 24px 0 16px;

            .logo {
                .logo-icon {
                    margin: 0 16px 0 0;

                    md-icon {
                        color: #FFFFFF;
                    }
                }

                .logo-text {
                    color: #FFFFFF;
                    font-size: 20px;
                    font-weight: 500;
                    white-space: nowrap;
                }
            }

            .bottom {
                margin-top: 8px;

                .search {
                    height: 48px;
                    min-height: 48px;
                    padding: 8px 8px 8px 16px;
                    background: #FFFFFF;

                    .icon {
                        margin: 0;
                    }

                    input {
                        color: rgba(0, 0, 0, 0.54);
                        padding-left: 16px;

                        &[type="number"] {
                            border-width: 0;
                        }
                    }

                    md-select {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }

                .date-filter-box {
                    background-color: #FFFFFF;
                    height: 48px;
                    margin-left: 8px;
                    padding-left: 16px;

                    .filter {
                        md-datepicker {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .content-card {
            .content {
                padding: 0;
            }
        }
    }
}
// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {
    .simple-datatable-page-layout {
        &.search-active {
            &.date-filter-active {
                .top-bg {
                    height: 256px;
                }

                .center {
                    .header {
                        height: 192px;
                        min-height: 192px;
                        max-height: 192px;

                        .bottom {
                            .date-filter-box {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }

        .center {
            .header {
                .bottom {
                    .date-filter-box {
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: $layout-breakpoint-xs) {
    .simple-datatable-page-layout {
        &.date-filter-active,
        &.search-active {
            .top-bg {
                height: 256px;
            }

            .center {
                .header {
                    height: 192px;
                    min-height: 192px;
                    max-height: 192px;

                    .bottom {
                        .date-filter-box {
                            margin-top: 8px;
                        }
                    }
                }
            }
        }

        &.search-active {
            &.date-filter-active {
                .top-bg {
                    height: 360px;
                }

                .center {
                    .header {
                        height: 296px;
                        min-height: 296px;
                        max-height: 296px;

                        .bottom {
                            .date-filter-box {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }

        .center {
            .header {
                .bottom {
                    .search-boxes {
                        min-height: 104px;

                        .search {
                            &:nth-child(2) {
                                margin: 8px 0 0;
                            }
                        }
                    }

                    .date-filter-box {
                        margin: 8px 0 0;
                        min-height: 96px;

                        .filter {
                            min-height: 48px;
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
