table.simple.simple-datatable {
    thead {
        tr {
            th {
                min-width: 40px;
                text-align: center;
                white-space: normal;
            }
        }
    }

    tbody {
        tr {
            td {
                min-width: 40px;

                &:last-child {
                    white-space: nowrap;

                    button:not(.md-icon-button) {
                        width: calc(100% - 12px);
                    }
                }

                &.nowrap {
                    white-space: nowrap;
                }

                div.text-boxed {
                    white-space: normal;
                }
            }
        }
    }
}
