#schedule-edit-page {
    .header {
        height: 120px;
        min-height: 120px;
        max-height: 120px;

        .goto-button {
            margin-right: 16px;
        }

        .schedule-image {
            overflow: hidden;
            width: 56px;
            height: 56px;
            margin-right: 16px;
            border: 3px solid rgba(0, 0, 0, 0.12);

            img {
                height: 100%;
                width: auto;
                max-width: none;
            }
        }

        .subtitle {
            margin: 6px 0 0;
        }
    }

    .schedule-edit-form-container {
        padding: 24px;
    }

    .schedule-edit-price-schemes-list {
        min-width: 320px;
        md-list-item.expand {
            box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),
                            0 4px 15px 0 rgba(0,0,0,0.15);
            margin: 16px 0;
        }
        label.label-near {
            bottom: 85%;
        }
    }

    [ty-price-scheme-edit], [ty-admin-reserve-multiple] {
        margin-bottom: 150px;
    }

    .schedule-edit-form-sales-report {
        .schedule-edit-form-sales-report-buttons {
            position: absolute;
            top: 12px;
            right: 24px;

            button {
                margin-top: 0 !important;
                margin-bottom: 0 !important;

                &:last-child {
                    margin-right: 0px !important;
                }
            }

            button + button {
                margin-left: 0px !important;
            }
        }
    }
    
    .schedule-delete {
        &.md-raised.md-warn {
            box-shadow: none;
        }
    }
}

.autocomplete-schedule-season-template {
    & > li {
        height: auto;
        line-height: normal;
        padding-top: 8px;
        padding-bottom: 8px;
        white-space: normal;
    }
}
