#schedule-select-dialog {
    .md-dialog-content {
        min-width: 50vw;

        .date-container {
            font-weight: 300;
            font-size: smaller;
            label {
                font-weight: 200;
            }
        }
    }
}
.schedule-select-dialog-md-autocomplete-menu {
    li {
        line-height: inherit;
        height: auto;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
