#schedule-quota {

    table.simple {

        thead {
            tr {
                th {
                    padding: 8px;
                    font-size: 14px;
                    text-align: center;

                    &:first-child {
                        min-width: 40px;
                    }
                }
            }
        }

        tbody {
            tr {
                &:hover {
                    background-color: #f0f0f0;
                }
                &.quota-busy {
                    opacity: 0.25;
                    background-color: rgba(0, 0, 0, .1);

                    &:hover {
                        background-color: rgba(0, 0, 0, .1);
                    }

                    &, & button, & a, & md-button, & [role="button"] {
                        cursor: default !important;
                    }
                }
                td {
                    padding: 8px;
                    font-size: 13px;

                    &:last-child {
                        min-width: 230px;
                    }

                    &[role="button"] {
                        cursor: pointer;
                    }

                    .md-button {
                        margin: 4px;
                        font-size: 14px;

                        &.dots {
                            margin: 0px;
                        }
                    }

                    .text-boxed {
                        white-space: normal;
                        font-weight: normal;
                        margin-left: 0;
                        margin-right: 0;
                    }

                    .md-accent-fg {
                        cursor: pointer;
                        border-bottom: 1px dashed;

                        &:hover, &:focus {
                            border-bottom-color: transparent;
                        }
                    }
                }
            }
        }

    }

}
@media screen and (max-width: $layout-breakpoint-xs) {
    #schedule-quota-invoice-edit-dialog {
        table.simple {
            max-width: 100%;
        }
    }
}

@media screen and (min-width: $layout-breakpoint-xs) and (max-width: $layout-breakpoint-sm) {
    #schedule-quota-invoice-edit-dialog {
        table.simple {
            max-width: 90%;
        }
    }
}
@media screen and (min-width: $layout-breakpoint-sm) and (max-width: $layout-breakpoint-md) {
    #schedule-quota-invoice-edit-dialog {
        table.simple, [ty-hall]:not(.hallscheme-size-big) {
            max-width: 70%;
        }
    }
}
@media screen and (min-width: $layout-breakpoint-md) {
    #schedule-quota-invoice-edit-dialog {
        table.simple, [ty-hall]:not(.hallscheme-size-big) {
            max-width: 55%;
        }
    }
}

#schedule-quota-invoice-edit-dialog {
    height: 100%;

    md-toolbar {
        md-progress-circular {
            path {
                stroke: white;
            }
        }

        legal-select {
            padding-top: 10px;

            md-input-container {
                margin-bottom: 0;
            }
        }
    }

    .schedule-quota-invoice-edit-dialog-toolbar {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        background-color: rgba(0, 0, 0, 0.03);
    }

    [ty-hall] {
        margin: 0 auto;
        max-width: 90%;

        .ty-hall-directive-hall-scheme .hall-schema-svg {
            .place {
                .seat {
                    fill-opacity: 0.5;
                }
                .seatnum {
                    font-weight: normal;
                }
                &.selected {
                    .seat {
                        fill: yellow;
                        fill-opacity: 1;
                    }
                    .seatnum {
                        fill: black;
                        font-weight: bold;
                    }
                }

                &.active_invoice {
                    fill: black;
                    .seat {
                        fill: black;
                        fill-opacity: 1;
                    }
                    .seatnum {
                        fill: white;
                        fill-opacity: 1;
                        font-weight: bold;
                    }
                }

                &.locked:not(.active_invoice), &.noninvolved:not(.active_invoice) {
                    .seat {
                        mask: url(#mask-stripe-diagonal);
                        fill: #666666;
                        fill-opacity: 0.7;
                    }
                    .seatnum {
                        fill: black;
                    }
                }
            }
        }
    }

    table.simple {
        margin: 16px auto 0 auto;
        font-size: 14px;

        thead {
            tr {
                th {
                    padding: 8px;
                    text-align: center;
                    min-width: initial;

                    &:first-child {
                        width: 60px;
                    }

                    md-checkbox {
                        margin-bottom: 4px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 8px;

                    md-checkbox {
                        margin: 0;

                        &.md-checked .md-icon {
                            border-color: rgba(255,255,255,0.87);
                        }
                    }

                }
            }
        }
    }

    .search-boxes {
        md-input-container {
            margin-bottom: 4px;

            .md-errors-spacer {
                display: none;
            }
        }
    }
}
