.ty-price-scheme-edit {

    .ty-price-scheme-edit__price-blocks-list {
        position: relative;

        .ty-price-scheme-edit__price-blocks-list-buttons {
            position: absolute;

            top: -24px;
            right: 0;

            & > button {
                margin: 0;
            }
        }
    }

    input.place_count {
        border-width: 0;
        max-width: 110px;
        text-align: center;
        padding: 0;
        line-height: initial;

        &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .ty-price-scheme-edit-no-places-add-button {
        cursor: pointer;
        white-space: pre-line;
    }

    &.ty-price-scheme-edit-hall {
        .place {
            &.occupied {
                .seat {
                    fill-opacity: .6;
                    stroke-width: 0;
                }

                &.selected {
                    .seat {
                        stroke-width: initial;
                        stroke: #666;
                        stroke-opacity: .65;
                    }
                    .seatnum {
                        fill: #555;
                    }
                }
            }
            &.sold_pos {
                .seat {
                    mask: url(#mask-stripe-diagonal);
                }
                .seatnum {
                    fill: #555;
                }
            }
            &.sold_vidzhet, &.sold_gate {
                .seat {
                    mask: url(#mask-stripe-diagonal-2);
                }
                .seatnum {
                    fill: #555;
                }
            }
            &.reserve {
                .seat {
                    stroke-opacity: 0.8 !important;
                    stroke-width: 3px;
                }
            }
            &.quota {
                .seat {
                    mask: url(#mask-stripe-vertical);
                }
                .seatnum {
                    fill: #555;
                }
            }
            &.admin_reserve {
                .seat {
                    mask: url(#mask-stripe-horizontal);
                }
                .seatnum {
                    fill: #555;
                }
            }
            &.sold_as_season {
                .seat {
                    stroke-width: 2px;
                    stroke: blue;
                    stroke-opacity: 1 !important;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                }
            }
        }
    }
    .hallscheme-size-big {
        .place {
            &.sold_as_season {
                .seat {
                    stroke-width: 1px;
                }
            }
        }
    }
}



table.simple.price-scheme-as-quota-editor-table {
    thead {
        tr {
            th {
                text-align: center;

                &:first-child {
                    min-width: 3%;
                    width: 3%;
                }
                &:last-child {
                    padding-right: 0px;
                    width: 52px;
                    max-width: 52px;
                }
                &.section {
                    min-width: 35%;
                    width: 35%;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 8px 4px;
                vertical-align: baseline;

                &:first-child {
                    padding-left: 12px;
                }
                &:last-child {
                    padding-right: 0px;
                    width: 52px;
                    max-width: 52px;
                }

                input {
                    max-width: 4em;
                    padding: 1px;

                    &.ng-invalid {
                        border: 1px solid red;
                        padding: 2px;
                    }
                }

                &.section {
                    padding-left: 12px;
                }
                &.price {
                    input {
                        max-width: 5em;
                    }
                }
            }
        }
    }
}
