$admin-reserve-multiple-place-colors: rgb(45,50,62) rgb(96,125,139) rgb(121,85,72);

.ty-admin-reservation-multiple-container {
    .like-md-list-item {
        margin: 4px;

        .like-md-list-item-button {
            font-size: 13px;

            > * {
                line-height: 1.2em;
                margin: 4px;
                color: rgba(0,0,0,0.54);
            }
            h3 {
                color: rgba(0,0,0,0.87);
                font-size: 16px;
            }
        }

        .like-md-list-item-secondary {
            .md-button {
                width: 40px;
                min-width: 40px;
                max-width: 40px;
                margin: 0;
            }
        }

        .psevdo-date-picker {

        }
    }

    [ty-hall] {
        .ty-hall-directive-hall-scheme .hall-schema-svg {
            .place {
                .seat {
                    fill-opacity: 0.6;

                    &.selected {
                        fill: yellow;
                    }
                }
                .seatnum {
                    font-weight: normal;
                }

                &.locked {
                    .seat {
                        mask: url(#mask-stripe-diagonal);
                        fill: #777777;
                        fill-opacity: 1;
                    }
                    .seatnum {
                        fill: black;
                    }

                    &.sold_pos {
                        .seat {
                            mask: url(#mask-stripe-diagonal);
                        }
                        .seatnum {
                            fill: #555;
                        }
                    }
                    &.sold_vidzhet, &.sold_gate {
                        .seat {
                            mask: url(#mask-stripe-diagonal-2);
                        }
                        .seatnum {
                            fill: #555;
                        }
                    }
                    &.reserve {
                        .seat {
                            fill: none;
                            // stroke-width: 2px;
                            stroke-opacity: 1 !important;
                        }
                    }
                    &.quota {
                        .seat {
                            mask: url(#mask-stripe-vertical);
                        }
                        .seatnum {
                            fill: #555;
                        }
                    }
                    &.sold_as_season {
                        .seat {
                            stroke-width: 2px;
                            stroke: blue;
                            stroke-opacity: 1 !important;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                        }
                    }
                }
            }
            @for $i from 1 through length($admin-reserve-multiple-place-colors) {
                .place.admin_reserve_multiple_color_#{$i} {
                    .seat {
                        fill: nth($admin-reserve-multiple-place-colors, $i);
                        stroke: nth($admin-reserve-multiple-place-colors, $i);
                        stroke-width: 2;
                        fill-opacity: 1;
                    }
                    .seatnum {
                        font-weight: bold;
                    }

                    &.noninvolved {
                        .seatnum {
                            fill: red;
                            stroke: white;
                            stroke-width: 0.1;
                        }
                    }

                    &.selected {
                        .seatnum {
                            fill: yellow;
                        }
                        .seat {
                            fill-opacity: 0.5;
                            stroke-width: 1;
                        }
                    }

                    &.locked {
                        .seat {
                            mask: url(#mask-stripe-diagonal-2);
                        }
                    }
                }
            }
        }
        &.hallscheme-size-big .ty-hall-directive-hall-scheme .hall-schema-svg {
            .place {
                &.sold_as_season {
                    .seat {
                        stroke-width: 1px;
                    }
                }
            }
        }
    }
    @for $i from 1 through length($admin-reserve-multiple-place-colors) {
        .admin_reserve_color_#{$i} {
            color: nth($admin-reserve-multiple-place-colors, $i) !important;
        }
    }
}

.admin-reservation-multiple-menu-content {
    md-input-container {
        margin-top: 10px;
        margin-bottom: 0;

        .md-errors-spacer {
            display: none;
        }
    }
}
