#planner-schedule-page-step2 {
    .header {
        h1, div.md-display-1 {
            white-space: pre;
        }
    }

    .schedule-template-list {
        .schedule-template-item {
            &.inactive {
                opacity: 0.65;
            }
        }
    }

    md-calendar {
        td {
            &:not(.md-calendar-selected-date) {
                .md-calendar-date-selection-indicator.planner_highlight {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }

    .weekday-checkbox {
        &:not(#weekday-checkbox--1) md-checkbox {
            margin-bottom: 10px;
        }
    }

    .d-ib {
        display: inline-block;
    }

    .d-i {
        display: inline;
    }
}
