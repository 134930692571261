.promocodes-list-datatable {
    thead {
        tr {
            th {
                &:last-child {
                    width: 1%;
                }
            }
        }
    }
    tbody {
        tr {
            td {
                &:first-child {
                    & > div:first-child {
                        cursor: pointer;
                    }
                    .text-boxed {
                        margin: 0 8px 0 0;
                        font-size: 14px;
                    }
                }
                promocode-rule-entity-schedule, promocode-rule-entity-event {
                    display: block;
                    font-size: 11px;
                    opacity: .75;
                }
                promocode-rule-entity-schedule {
                    br {
                        display: none;
                    }
                    .name:after {
                        content: ', ';
                    }
                }
            }
        }
    }
}
