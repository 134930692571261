.ty-admin-reservation-container {
    [ty-hall] .ty-hall-directive-hall-scheme {
        .hall-schema-svg {
            .place {
                &.selected {
                    .seat {
                        fill-opacity: 1;
                        fill: #000000;
                        stroke: #000000;
                        stroke-dasharray: 2;
                    }

                    .seatnum {
                        fill: #ffffff;
                    }
                }
            }
        }
    }
}
